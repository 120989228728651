import { MapboxSearchBox } from "@mapbox/search-js-web";
import type { Feature, Point } from "geojson";
import { LngLat, Marker } from "mapbox-gl";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import App from "../App";
import { Filter } from "../Filter";
import { ChargerModel, PaymentMethodModel, ProviderModel, Station, StationModel, } from "../Station";
import Partners from "./Partners.vue";
import { Loader } from '@googlemaps/js-api-loader';

@Component({
    components: {
        Partners,
    },
})
export default class Home extends Vue {
    private filtersExpanded = false;
    protected documentTitle = "Mapa nabíjecích stanic | fDrive.cz";
    protected documentDescr =
                  "Naplánujete si svojí cestu elektromobilem, zjistěte kde se můžete občerstvit a již vícekrát neztrácejte čas hledáním nabíjecí stanice.";
    protected searchPlaceholder = "Hledat adresu";
    protected language = "cs";
    protected proximityBias = new LngLat(15.473118, 49.757362);
    protected borderRadius = "0";
    private positionLabel = "pos-";

    @Prop(Filter) filter!: Filter;
    @Prop(Boolean) stationsLoaded!: boolean;
    @Prop(Boolean) mapLoaded!: boolean;
    @Prop(Boolean) showPartners!: boolean;

    @Watch("stationsLoaded")
    private onStationsLoadedChanged(value: boolean) {
        this.filterMarkers();
    }

    @Watch("filtersExpanded")
    private onFiltersExpandedChanged() {
        setTimeout(() => {
            (<App>this.$parent).mapBox?.resize();
        }, 300);
    }

    private mounted() {
        if (this.$route.name !== "home") {
            this.routeChanged(this.$route);
        }

        console.log("aDAFDSFDSF");

        this.initSearch();

        // init DFP tag
        try {
            (window as any).googletag.cmd.push(() => {
                (window as any).googletag.display(this.positionLabel + "01");
            });
        } catch (err) {
            //
        }

        // set document meta
        document.title = this.documentTitle;
        document
        .querySelector("head > meta[name=\"description\"]")!
        .setAttribute("content", this.documentDescr);
    }

    private destroyed() {
        this.positionMarker.remove();
    }

    private initSearch() {
        const app = <App>this.$parent;

        console.log("aaaa");

        const loader = new Loader({
            apiKey: app.$data.googleApiKey,
            version: "weekly",
            libraries: ["places"]
        });

        loader
        .importLibrary('places')
        .then((e) => {
            console.log(e);
        })
        .catch((e) => {
            // do something
        });

        console.log(loader);

        const searchBox = new MapboxSearchBox();

        searchBox.accessToken = app.$data.apiKey;
        searchBox.options = {
            language: this.language,
            proximity: this.proximityBias
        };
        searchBox.placeholder = this.searchPlaceholder;
        searchBox.theme = {
            variables: {
                fontFamily: "Roboto, sans-serif",
                unit: "14px",
                borderRadius: this.borderRadius,
                boxShadow: "none",
            }
        };

        searchBox.addEventListener("retrieve", (e) => {
            const mapBox = app.mapBox;
            if (!mapBox) {
                return;
            }
            for (const marker of mapBox._markers) {
                marker.remove();
            }

            const location = new LngLat(e.detail.features[0].geometry.coordinates[0], e.detail.features[0].geometry.coordinates[1]);

            this.positionMarker.setLngLat(location);
            this.positionMarker.addTo(mapBox);

            mapBox.easeTo({
                center: location,
                zoom: 16,
            });
        });

        searchBox.addEventListener("clear", () => {
            this.positionMarker.remove();
        });

        searchBox.addEventListener("suggest", (event) => {
            this.filtersExpanded = true;
        });

        document.querySelector(".charging-map__panel__main__address")?.appendChild(searchBox as Node);
    }

    get providersSorted() {
        const providers: Array<ProviderModel> = (<App>this.$parent).$data.providers;

        if (Object.keys(providers).length) {
            return Object.entries(providers)
                         .filter(([id, provider]) =>
                                 provider.show && ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
                                     station.providers?.find((_provider) => _provider.toString() === id)
                                 )
                         )
                         .sort(([a_id, a_provider], [b_id, b_provider]) => {
                             const textA = a_provider.name.toUpperCase();
                             const textB = b_provider.name.toUpperCase();
                             return textA < textB ? -1 : textA > textB ? 1 : 0;
                         });
        } else {
            return [];
        }
    }

    get chargerTypesSorted() {
        const chargers: Array<ChargerModel> = (<App>this.$parent).$data.charger_types;

        if (Object.keys(chargers).length) {
            // filter out not used properties
            return Object.entries(chargers)
                         .filter(([id, charger]) =>
                                 charger.show && ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
                                     station.chargers?.find((_charger) => _charger.id === id)
                                 )
                         )
                         .sort(([a_id, a_charger], [b_id, b_charger]) =>
                             a_charger.order < b_charger.order
                                 ? -1
                                 : a_charger.order > b_charger.order
                                     ? 1
                                     : 0
                         );
        } else {
            return [];
        }
    }

    get paymentMethodsSorted() {
        const methods: Array<PaymentMethodModel> =
                  (<App>this.$parent).$data.payment_methods;

        if (Object.keys(methods).length) {
            return Object.entries(methods)
                         .filter(([id, method]) =>
                             ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
                                 station.payment_methods?.find(
                                     (_method) => _method.toString() === id
                                 )
                             )
                         )
                         .sort(([a_id, a_method], [b_id, b_method]) =>
                             a_method.order < b_method.order
                                 ? -1
                                 : a_method.order > b_method.order
                                     ? 1
                                     : 0
                         );
        } else {
            return [];
        }
    }

    private filterTypeClicked(value: string, pushState = true) {
        if (value === this.filter.type) {
            this.filter.type = "";
        } else {
            this.filter.type = value;
        }
        this.updateFilter();
    }

    @Watch("filter.charger_type")
    private onFilterChargerTypeChanged(value: string) {
        this.updateFilter();
    }

    @Watch("filter.min_output")
    private onFilterMinOutputChanged(value: string) {
        this.updateFilter();
    }

    @Watch("filter.payment_method")
    private onFilterPaymentMethodChanged(value: string) {
        this.updateFilter();
    }

    @Watch("filter.provider")
    private onFilterProviderChanged(value: string) {
        this.updateFilter();
    }

    @Watch("$route")
    private routeChanged(route: Route) {
        if (route.params.type) {
            this.filter.type = route.params.type;
        } else {
            this.filter.type = "";
        }

        if (route.params.charger_type) {
            this.filter.charger_type = route.params.charger_type;
        } else {
            this.filter.charger_type = "";
        }

        if (route.params.min_output) {
            this.filter.min_output = parseInt(route.params.min_output, 10);
        } else {
            this.filter.min_output = 0;
        }

        if (route.params.payment_method) {
            this.filter.payment_method = route.params.payment_method;
        } else {
            this.filter.payment_method = "";
        }

        if (route.params.provider) {
            this.filter.provider = route.params.provider;
        } else {
            this.filter.provider = "";
        }

        this.filterMarkers();
    }

    private updateFilter() {
        this.$router.push((<App>this.$parent).getFilterUrl()).catch((reason) => {
            if (reason.name !== "NavigationDuplicated") {
                throw reason;
            }
        });
    }

    private filterMarkers() {
        const parent = <App>this.$parent;
        const mapBox = parent.mapBox;
        if (!mapBox || !parent.$data.dataSource) {
            console.log("no data", mapBox, parent.$data);
            return;
        }

        const geoJsonSource = mapBox.getSource("stations");
        if (geoJsonSource?.type === "geojson") {
            geoJsonSource.setData({
                type: "FeatureCollection",
                features: parent.$data.dataSource.features?.filter((feature: Feature<Point, StationModel>) => {
                    return feature.properties &&
                        this.filterMarkerType(feature.properties) &&
                        this.filterMarkerProvider(feature.properties) &&
                        this.filterMarkerChargerType(feature.properties) &&
                        this.filterMarkerPaymentMethod(feature.properties) &&
                        this.filterMarkerMinOutput(feature.properties);
                })
            });
        }
    }

    private filterMarkerType(station: Station) {
        return (
            this.filter.type === "" ||
            (this.filter.type === "rychlonabijeci" &&
                station.fast_charging === true) ||
            (this.filter.type === "standardni" &&
                station.fast_charging === false)
        );
    }

    private filterMarkerProvider(station: Station) {
        return (
            this.filter.provider === "" ||
            station.providers.find((id) => {
                return id.toString() === this.filter.provider;
            }) !== undefined
        );
    }

    private filterMarkerChargerType(station: Station) {
        return (
            this.filter.charger_type === "" ||
            (Array.isArray(station.chargers) &&
                station.chargers.find(
                    (charger) => charger.id === this.filter.charger_type
                ) !== undefined)
        );
    }

    private filterMarkerPaymentMethod(station: Station) {
        return (
            this.filter.payment_method === "" ||
            (Array.isArray(station.payment_methods) &&
                station.payment_methods.find(
                    (id) => id.toString() === this.filter.payment_method
                ) !== undefined)
        );
    }

    private filterMarkerMinOutput(station: Station) {
        return (
            this.filter.min_output === 0 ||
            (Array.isArray(station.chargers) &&
                station.chargers.find(
                    (charger) =>
                        parseInt(charger.max_output, 10) >= this.filter.min_output
                ) !== undefined)
        );
    }

    public get filtersSum(): string {
        const app = <App>this.$parent;
        const filters: string[] = [];

        if (this.filter.charger_type)
            filters.push(app.charger_types[this.filter.charger_type].name);
        if (this.filter.min_output) filters.push(`${ this.filter.min_output }+ kW`);
        if (this.filter.payment_method)
            filters.push(app.payment_methods[this.filter.payment_method].name);
        if (this.filter.provider)
            filters.push(app.providers[this.filter.provider].name);

        if (filters.length > 2) {
            return `(${ filters[0] } a ${ filters.length - 1 } další …)`;
        } else if (filters.length > 1) {
            return `(${ filters[0] }, ${ filters[1] })`;
        } else if (filters.length) {
            return `(${ filters[0] })`;
        }

        return "";
    }

    private positionMarker = new Marker({
        element: this.createPositionMarkerElement(),
    });

    /**
     * Returns Element for search result Marker
     */
    private createPositionMarkerElement(): HTMLImageElement {
        const el = document.createElement("img");
        el.src = `/resources/5/img/mapa/point_search.svg`;
        el.width = 30;
        el.height = 37.5;
        return el;
    }
}
