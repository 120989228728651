import { GeoJSONFeature, GeolocateControl, LngLatBounds, Map, NavigationControl } from "mapbox-gl";
import Vue from "vue";
import { RawLocation } from "vue-router/types/router";
import { Filter } from "./Filter";
import { ChargerModel, DataSource, ManufacturerModel, OutputRange, ProviderModel, Station } from "./Station";
import "mapbox-gl/dist/mapbox-gl.css";

export abstract class AppBase extends Vue {
    /** MapBox API key */
    protected mapBoxApiKey = "pk.eyJ1IjoiMjRuZXQiLCJhIjoiY204bHB4aG54MWFkZzJsc2FlODN6NHU0NSJ9.LR0EUU1qe7ejNEiT0fuGnA";
    /** Google API key */
    protected googleApiKey = "AIzaSyAhwIPfyHw1IFuO8J2_Xacg3bl8ijGt4oo";
    protected defaultBounds = new LngLatBounds([[14.365875, 50.038817], [14.527408, 50.124627]]);
    /** Customize colors of station pins */
    protected pinColors = {
        fast: "orange",
        regular: "green",
        selected: "yellow",
        wip: "grey",
        cluster: "dark_grey",
    };
    protected allowGeoAccessMessage = "Povolte prosím přístup k poloze ve Vašem prohlížeči pro získání plné funkcionality.";

    /** MapBox object */
    public mapBox: Map | undefined = undefined;
    private dataSource: DataSource | null = null;
    /** List of all Stations */
    private stations: Station[] = [];
    /** List of all available Providers */
    public providers: Array<ProviderModel> = [];
    /** List of all available Charger types */
    public charger_types: Array<ChargerModel> = [];
    /** List of all available Payment Methods */
    public payment_methods: Array<number> = [];
    /** List of all available station Manufacturers */
    public manufacturers: Array<ManufacturerModel> = [];
    /** Power output range to offer in filter */
    public output_range: OutputRange = { min: 0, max: 350 };
    /** Is JSON of stations already loaded? */
    private stationsLoaded = false;
    /** Is Google Map already attached to DOM? */
    private mapLoaded = false;
    /** Active filter values object */
    private filter = new Filter({
        type: "",
        provider: "",
        charger_type: "",
        payment_method: "",
        min_output: 0,
    });
    /** Marker for a place from Goole Maps Places search */
    private searchMarker: google.maps.Marker | undefined = undefined;
    /** URL to load JSON of stations */
    protected abstract sourceUrl: string;

    protected mounted() {
        const geoLocateControl = new GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserHeading: true
        });

        this.mapBox = new Map({
            container: "embed-map",
            bounds: this.defaultBounds,
            accessToken: this.mapBoxApiKey,
            style: "mapbox://styles/24net/cm8lsu6vp01aq01qre7e541wm",

        })
        .on("load", async (e) => {
            if (!await this.loadStations() || !this.dataSource) {
                return;
            }

            e.target.addSource("stations", {
                type: "geojson",
                data: this.dataSource,
                cluster: true,
            });

            e.target.loadImage(`/resources/5/img/mapa/point_${ this.pinColors.fast }.webp`, (error, image) => {
                if (error) throw error;
                e.target.addImage("point-fast-icon", image!);
            });
            e.target.loadImage(`/resources/5/img/mapa/point_${ this.pinColors.regular }.webp`, (error, image) => {
                if (error) throw error;
                e.target.addImage("point-regular-icon", image!);
            });
            e.target.loadImage(`/resources/5/img/mapa/point_${ this.pinColors.wip }.webp`, (error, image) => {
                if (error) throw error;
                e.target.addImage("point-wip-icon", image!);
            });
            e.target.loadImage(`/resources/5/img/mapa/cluster_${ this.pinColors.cluster }.webp`, (error, image) => {
                if (error) throw error;
                e.target.addImage("cluster-icon", image!);
            });

            e.target.addLayer({
                id: "clusters",
                type: "symbol",
                source: "stations",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": ["get", "point_count_abbreviated"],
                    "text-font": ["Roboto Bold", "Arial Unicode MS Bold"],
                    "text-size": 14,
                    "icon-image": "cluster-icon",
                    "icon-size": 0.5
                },
                paint: {
                    "text-color": "#ffffff",
                }
            });

            e.target.addLayer({
                id: "stations-fast-layer",
                type: "symbol",
                source: "stations",
                layout: {
                    "icon-image": "point-fast-icon",
                    "icon-size": 0.5
                },
                filter: ["all", ["==", "fast_charging", true], ["==", "status", "open"]]
            });

            e.target.addLayer({
                id: "stations-slow-layer",
                type: "symbol",
                source: "stations",
                layout: {
                    "icon-image": "point-regular-icon",
                    "icon-size": 0.5
                },
                filter: ["all", ["==", "fast_charging", false], ["==", "status", "open"]]
            });

            e.target.addLayer({
                id: "stations-wip-layer",
                type: "symbol",
                source: "stations",
                layout: {
                    "icon-image": "point-wip-icon",
                    "icon-size": 0.5
                },
                filter: ["any", ["==", "status", "tempClosed"], ["==", "status", "inDev"]]
            });

            this.mapLoaded = true;
            // init geolocation if on home page
            if (this.$route.name !== "stationDetail") {
                geoLocateControl.trigger();
            }
        })
        .addControl(geoLocateControl)
        .addControl(new NavigationControl())
        .on("click", ["stations-fast-layer", "stations-slow-layer", "stations-wip-layer"], (e) => {
            e.preventDefault();
            this.showStationFromGeoJSON(e.features?.at(0)?.properties);
        })
        .on("click", "clusters", (e) => {
            const features = e.target.queryRenderedFeatures(e.point, { layers: ["clusters"] });
            const clusterId = features[0].properties?.cluster_id;
            if (!clusterId) return;
            // @ts-expect-error typings
            e.target.getSource("stations")?.getClusterExpansionZoom(
                clusterId,
                (err: Error | undefined, zoom: number) => {
                    if (err) return;

                    e.target.easeTo({
                        // @ts-expect-error typings
                        center: features[0].geometry.coordinates,
                        zoom
                    });
                }
            );
        })
        .on("mouseenter", ["stations-fast-layer", "stations-slow-layer", "stations-wip-layer", "clusters"], (e) => {
            e.target.getCanvas().style.cursor = "pointer";
        })
        .on("mouseleave", ["stations-fast-layer", "stations-slow-layer", "stations-wip-layer", "clusters"], (e) => {
            e.target.getCanvas().style.cursor = "";
        })
        .on("sourcedata", (e) => {
            if (!this.stationsLoaded && e.sourceId === "stations" && e.isSourceLoaded) {
                this.stationsLoaded = true;

                if (this.$route.name === "stationDetail") {
                    // center map to marker if page loaded on detail
                    const station = this.getStationById(parseInt(this.$route.params.id, 10));
                    if (station) {
                        e.target.setZoom(15);
                        e.target.setCenter([station.location.long, station.location.lat]);
                    }
                }
            }
        });
    }

    /**
     * Load list of all Stations form server
     */
    private async loadStations(): Promise<undefined | DataSource> {
        try {
            const fetchResponse = await fetch(this.sourceUrl);
            this.dataSource = await fetchResponse.json();
            if (!this.dataSource) {
                throw new Error("DataSource is empty");
            }

            this.stations = this.dataSource.features.map((feature) => ({
                ...feature.properties,
                location: {
                    lat: feature.geometry.coordinates[1],
                    long: feature.geometry.coordinates[0],
                    address: feature.properties.location.address,
                    country: feature.properties.location.country
                }
            } satisfies Station));
            this.providers = this.dataSource.providers;
            this.charger_types = this.dataSource.charger_types;
            this.payment_methods = this.dataSource.payment_methods;
            this.manufacturers = this.dataSource.manufacturers;

            return this.dataSource;
        } catch (reason) {
            (window as any).App.message.show(
                "Nepodařilo se načíst mapové podklady. Zkuste stránku znovu načíst.",
                "fail"
            );
            console.error(reason);
            return undefined;
        }
    }

    /**
     * Fit the default bounds in the map view
     */
    protected fitCountryInView() {
        this.mapBox?.fitBounds(this.defaultBounds);
    }

    private showStationFromGeoJSON(properties: GeoJSONFeature["properties"] | undefined) {
        if (!properties) {
            return;
        }

        for (const [key, value] of Object.entries(properties)) {
            if (["location", "providers", "chargers", "cables", "payment_methods", "pics", "opening_hours"].includes(key)) {
                properties[key] = JSON.parse(value);
            }
        }

        this.showStation(properties as Station);
    }

    /**
     * Show Station detail by provided Station object
     */
    private showStation(station: Station) {
        if (!station.url || !station.id) {
            (window as any).App.message.show("Tuto stanici se nepodařilo nalézt.", "fail");
            return;
        }

        this.$router.push(`/${ station!.url }-${ station!.id }`);
    }

    /**
     * Return current URL based on current Filter values
     */
    public getFilterUrl(): RawLocation {
        let url = "";

        switch (this.filter.type) {
            case "rychlonabijeci":
                url = "/t/" + this.filter.type;
                break;
            case "standardni":
                url = "/t/" + this.filter.type;
                break;
        }

        if (this.filter.charger_type !== "") {
            url += "/c/" + this.filter.charger_type;
        }

        if (this.filter.min_output !== 0) {
            url += "/w/" + this.filter.min_output;
        }

        if (this.filter.payment_method !== "") {
            url += "/m/" + this.filter.payment_method;
        }

        if (this.filter.provider !== "") {
            url += "/p/" + this.filter.provider;
        }

        return url ? url : { name: "home" };
    }

    /**
     * Returns Station by ID
     *
     * @param id Station ID
     */
    public getStationById(id: number): Station | undefined {
        if (!this.stationsLoaded) {
            return undefined;
        }

        return this.stations.find((station) => station.id === id);
    }
}
